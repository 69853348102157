import React, { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useHistory } from "react-router";
let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: () => {
    if (localStorage.getItem("token")) return true;
    else return false;
  },
  login: (token) => {},
  logout: () => {
    console.log("logout");
  },
  getToken: () => {
    return localStorage.getItem("token") || null;
  },
});

// const calculateRemainingTime = (expirationTime) => {
//   const currentTime = new Date().getTime();
//   const adjExpirationTime = new Date(expirationTime).getTime();

//   const remainingDuration = adjExpirationTime - currentTime;

//   return remainingDuration;
// };

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  // const storedExpirationDate = localStorage.getItem('expirationTime');

  // const remainingTime = calculateRemainingTime(storedExpirationDate);

  // if (remainingTime <= 3600) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('expirationTime');
  //   return null;
  // }

  return {
    token: storedToken,
    // duration: remainingTime,
  };
};

const retrieveStoredUserDetails = () => {
  const storedData = localStorage.getItem("user_details");
  return {
    user_details: storedData,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken = null;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  let initialUserValue = null;
  const userData = retrieveStoredUserDetails();
  if (userData) {
    initialUserValue = userData.user_details;
  }
  const [token, setToken] = useState(initialToken);
  const [userDetails, setuserDetails] = useState(initialUserValue);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    // console.log("logout handler");
    initialToken = null;
    initialUserValue = null;
    setToken(null);
    setuserDetails(null);
    AuthContext.token = null;
    AuthContext.isLoggedIn = false;
    localStorage.removeItem("token");
    localStorage.clear();
  }, []);

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
    // localStorage.setItem('expirationTime', expirationTime);

    // const remainingTime = calculateRemainingTime(expirationTime);

    // logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const setUserDetailsFun = (details) => {
    setuserDetails(details);
    localStorage.setItem("user_details", JSON.stringify(details));
  };

  useEffect(() => {
    if (tokenData) {
      // console.log(tokenData.duration);
      // logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);
  const checkErrorStatus = (err) => {
    if ((err && err.status === 401) || err.data.tokenExpire) {
      toast.success("Session expired. Please login again");
      logoutHandler();
      return true;
    }
    return false;
  };

  const checkLoginStatus = () => {
    if (localStorage.getItem("token")) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL_NODE}/checkloginstatus`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {})
        .catch((err) => {
          checkErrorStatus(err.response);
        });
    }
  };
  const history=useHistory();
  const checkTimeOutStatus = (payload) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL_NODE}/enterprise/v2/check-timeout-status?${payload}`
      )
      .then((res => {}))
      .catch((err) => {
        if(err.response.data && err.response.data.message==='REQUEST TIMED OUT'){
          history.push({
            pathname: "/vakil/report-status",
            data: err?.response?.data?.message,
          });
        }else if (err.response.data && err.response.data.message==='USER NOT REGISTERED'){
          history.push({
            pathname: "/vakil/report-status",
            data: err?.response?.data?.message,
          });
        }else if(err.response.data && err.response.data.message==="UNAUTHORIZED"){
          history.push({
            pathname: "/vakil/report-status",
            data: err?.response?.data?.message,
          });
        }
      });
  };

  const contextValue = {
    token: token,
    isLoggedIn: () => {
      if (localStorage.getItem("token")) return true;
      else return false;
    },
    userData: () => {
      if (localStorage.getItem("user_details")) {
        let userData = localStorage.getItem("user_details");
        return JSON.parse(userData);
      } else {
        return null;
      }
    },
    login: loginHandler,
    logout: logoutHandler,
    setUserDetail: setUserDetailsFun,
    getToken: () => {
      if (!localStorage.getItem("token")) AuthContext.token = null;
      return localStorage.getItem("token") || null;
    },
    checkErrorStatus,
    checkLoginStatus,
    checkTimeOutStatus
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
