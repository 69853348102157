import React, { Component } from "react";
import styles from "./CssModules/mainSection.module.css";
import Image from "../../assets/LP_final_reduce.jpg";

import { TypeAnimation } from "react-type-animation";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Partners from "./Partners";

const Header = () => {
  const [navHeight, setNavHeight] = useState(undefined);
  const [userIsDesktop, setUserIsDesktop] = useState(undefined);
  const [topRem, settopRem] = useState(51);
  const history = useHistory();

  const handleResize = () => {
    let navBar = document.querySelector("nav");

    setNavHeight(navBar.offsetHeight ? navBar.offsetHeight : 71);
    window.innerWidth > 991
      ? setTimeOutFunction(true)
      : setTimeOutFunction(false);
    if (window.innerWidth > 0 && window.innerWidth < 1400) {
      if (window.innerWidth < 500) {
        settopRem(0);
      } else {
        settopRem(2);
      }
    }
    if (window.innerWidth > 1400) {
      settopRem(13);
    }
  };

  const setTimeOutFunction = (value) => {
    setUserIsDesktop(value);
  };

  const navigateTo = (id) => {
    const section = document.querySelector(id);
    const sectionTop = section.offsetTop;
    window.scrollTo({ top: sectionTop - 60 });
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  // 50,45

  return (
    <>
      <div
        className="container lkgapbetweencontainer"
        style={{
          paddingTop: `${!userIsDesktop ? navHeight : navHeight / 2 + 3}px`,
          marginBottom: "40px",
          marginTop: `${topRem}rem`,
          paddingBottom: `${!userIsDesktop ? 0 : navHeight / 2}px`,
        }}
      >
        <div className="row">
          <div className={`col-sm-12 col-md-6 col-lg-6  ${styles.des}`}>
            {!userIsDesktop && (
              <p
                style={{
                  fontSize: "11px",
                  color: "#0f9b51",
                  backgroundColor: "#e8fae8",
                  borderRadius: "4px",
                  paddingLeft: "9px",
                  paddingRight: "8px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  marginBottom: "4px",
                  marginTop: "0",
                }}
              >
                To generate title report, kindly access the platform from
                laptop/desktop
              </p>
            )}
            {/* <h1 className="">Lending Katalyst</h1> */}
            <h1 className="">
              Simplifying property title search for -{" "}
              <span
                style={{
                  color: "#0f9b51",
                }}
              >
                you.
              </span>
            </h1>
            <TypeAnimation
              // Same String at the start will only be typed once, initially
              sequence={[
                "Instant report.",
                1000,
                "360-degree check.",
                1000,
                "Customisable report.",
                1000,
              ]}
              speed={50} // Custom Speed from 1-99 - Default Speed: 40
              style={{ fontSize: "2em", color: "#0f9b51", fontWeight: "900" }}
              wrapper="p" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
            <div className={styles.actionbuttons}>
              <div
                className={styles.subContainer2}
                style={{
                  paddingBottom: "0",
                }}
              >
                <a
                  onClick={() => navigateTo("#whatwedo")}
                  className={styles.learnmore}
                  id={styles.learnmore}
                >
                  Learn More
                </a>
              </div>
              <div
                className={styles.button}
                onClick={() => history.push("/demo")}
              >
                Request Demo
              </div>
            </div>
          </div>
          <div
            className={`col-sm-12 col-md-6 col-lg-6 d-flex ${styles.imagediv}`}
          >
            <img
              src={Image}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                minHeight: "460px",
                maxWidth: "540px",
                objectFit: "contain",
              }}
              className={styles.mainheaderimage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
