import React, { Suspense, useContext, useEffect, useState, lazy } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
// import UserProfile from "./components/Profile/UserProfile";
import HomePage from "./pages/HomePage";
import AuthContext from "./store/auth-context";
import Upload from "./components/Forms/FormsProceed";
import UploadFormContextProvider from "./store/UploadFormContext";

import { Toaster } from "react-hot-toast";
import "./App.css";
import OverlayComp from "./components/Overlay/OverlayComp";

import AOS from "aos";
import "aos/dist/aos.css";
import 'react-datepicker/dist/react-datepicker.css';

// import NewDashboard from "./components/Dashboard/NewDashboard";
import ComingSoon from "./components/ComingSoon/ComingSoon";

import ReportStatusPage from "./components/ReportStatusPage/ReportStatusPage";
import LendingSpinner from "./components/Spinner/LendingSpinner";
// import LibraFooter from "./vakilsearch/components/Footer/LibraFooter";

const PartitionReviewSplitPdf = lazy(() =>
  import("./components/SplitPDF/PartitionReviewSplitPdf")
);

// import ReportsPage from ;

/*
 * @description : Components for LK
 */
const ReportsPage = lazy(() => import("./pages/ReportsPage"));

const LKPartitionReviewSplitPdf = lazy(() =>
  import("./components/SplitPDF/LKPartitionReviewSplitPdf")
);
const GenerateTitleReportForm =lazy(()=>import("./pages/PropertyDetailsForm/GenerateTitleReportForm"));

const DemoPage = lazy(() => import("./pages/DemoPage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const PrivacyPolicys = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const Careers = lazy(() => import("./components/Careers/Careers"));
const Aboutus = lazy(() => import("./components/Aboutus/Aboutus"));
const Pricing = lazy(() => import("./components/Pricing/Pricing"));
const DraftDeed = lazy(() => import("./pages/DraftDeed"));
const EditDraft = lazy(() =>
  import("./components/Dashboard/DraftDeed/EditDraft")
);
const EditReports = lazy(() =>
  import("./components/Dashboard/ReportEditor/EditReports")
);
const SelectBank=lazy()
const FAQs = lazy(() => import("./pages/FAQs"));
const ForgotPassword = lazy(() =>
  import("./components/ForgotPassword/ForgotPassword")
);
const ContactUsPage = lazy(() =>
  import("./components/ContacusPage/ContactUsPage")
);
const DragDropFile = lazy(() => import("./components/DragDrop/DragDrop"));
const ReportEditorTable = lazy(() =>
  import("./components/Dashboard/ReportEditorTable/ReportEditorTable")
);
const ClientEditReport = lazy(() =>
  import("./components/Dashboard/ReportEditor/ClientEditReport")
);
const AllowUser = lazy(() => import("./components/Allouser/AllowUser"));
const AuthPage = lazy(() => import("./pages/AuthPage"));
const AdminDashboard=lazy(()=>import('./components/AdminDashboard/AdminDashboard'))

/*
  Compoent For VakilSearch client
*/
const VakilSearchDashboard = lazy(() =>
  import("./vakilsearch/components/Dashboard/VakilSearchDashboard")
);
const SegregateUploadPage = lazy(() =>
  import("./vakilsearch/components/UploadDocsPage/SegregateUploadPage")
);
const PropertyDetailsForm = lazy(() => import("./pages/PropertyDetailsForm"));
const VakilSearchPropertyDetailsForms = lazy(() =>
  import("./vakilsearch/components/Form/VakilSearchPropertyDetailsForms")
);
const VakilReports = lazy(() =>
  import("./vakilsearch/components/Dashboard/Report/VakilReports")
);
const VakilEditReports = lazy(() =>
  import("./vakilsearch/components/Dashboard/ReportEditor/VakilEditReports")
);
const VakilPartitionReviewSplitPdf = lazy(() =>
  import("./vakilsearch/components/VakilSplitPDF/VakilPartitionReviewSplitPdf")
);
const VakilReportEditorTable = lazy(() =>
  import(
    "./vakilsearch/components/Dashboard/ReportEditorTable/VakilReportEditorTable"
  )
);
const VakilStatusPage = lazy(() =>
  import("./vakilsearch/components/VakilReportStatusPage/VakilStatusPage")
);

/*
 * Compoent for VakilSearch client ends here
 */

function App() {
  const authCtx = useContext(AuthContext);
  const userData = authCtx.userData();
  const history = useHistory();
  const location = useLocation();

  // const [enableVerify, setEnableVerify] = useState(false);
  const [enableOverlay, setEnableOverlay] = useState(false);

  useEffect(() => {
    let boolValue = false;
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        boolValue = true;
      } else {
        boolValue = false;
      }
      setEnableOverlay(boolValue);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [enableOverlay]);

  const checkIfEnterPriseRoute = () => {
    if (
      window.location.pathname.includes("editreports") ||
      window.location.pathname.includes("partitionreview") ||
      window.location.pathname.includes('vakil')
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if(!checkIfEnterPriseRoute())
      if (!checkIfEnterPriseRoute()) authCtx.checkLoginStatus();
   
    AOS.init();
    AOS.refresh();
    if ('caches' in window) {
      try {
        caches.delete('files-cache');
      } catch (error) {
        console.error('Error deleting cache');
      }
    }
  }, []);
  useEffect(() => {
    if (location && location.pathname === "/") {
      let mainTag = document.querySelector("main");
      if (mainTag) {
        mainTag.style.overflowX = "hidden";
      }
    } else {
      let mainTag = document.querySelector("main");
      if (mainTag) {
        mainTag.style.overflowX = null;
      }
    }
    // console.log("location-->", location.pathname);
  }, [location]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathname]);

    return null;
  };
  return (
    <Layout data={{ route: location.pathname }}>
      <ScrollToTop />
      <Toaster
        position="top-right"
        containerClassName="toast-class"
        gutter={12}
        toastOptions={{
          duration: 2000,
          className: "toast-class",
          style: {
            padding: "10px 20px",
            boxShadow: "0 0 10px black",
            fontSize: "18px",
            marginBottom: "20px",
            marginLeft: "20px",
            borderRadius: "4px",
          },
        }}
      />
      <Switch>
        {/* <Suspense fallback={<LendingSpinner/>}> */}

        <Route path="/" exact>
          <HomePage />
        </Route>
        {!authCtx.isLoggedIn() && (
          <Route
            path="/login"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <AuthPage />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <AuthPage />} */}
          </Route>
        )}

        {!authCtx.isLoggedIn() && (
          <Route
            path="/signup"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <SignupPage />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <SignupPage />} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            path="/dashboard"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <Dashboard />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <Dashboard />} */}
          </Route>
        )}
          {authCtx.isLoggedIn() &&   userData.role==='admin' && (
          <Route
            path="/admin-dashboard"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <AdminDashboard />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <Dashboard />} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            path="/forms"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? (
                  <OverlayComp />
                ) : (
                  <UploadFormContextProvider>
                    <GenerateTitleReportForm />
                  </UploadFormContextProvider>
                )}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? (
              <OverlayComp />
            ) : (
              <UploadFormContextProvider>
                <PropertyDetailsForm />
              </UploadFormContextProvider>
            )} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            path="/segregate/forms"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? (
                  <OverlayComp />
                ) : (
                  <UploadFormContextProvider>
                    <PropertyDetailsForm />
                  </UploadFormContextProvider>
                )}
              </Suspense>
            )}
          ></Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            path="/draft-deed"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? (
                  <OverlayComp />
                ) : (
                  <UploadFormContextProvider>
                    <DraftDeed />
                  </UploadFormContextProvider>
                )}
              </Suspense>
            )}
          ></Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route path="/formproceed">
            {enableOverlay ? (
              <OverlayComp />
            ) : (
              <UploadFormContextProvider>
                <Upload />
              </UploadFormContextProvider>
            )}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/reports"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <ReportsPage />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <ReportsPage />} */}
          </Route>
        )}

        {/* {authCtx.isLoggedIn() && (
          <Route path="/segregate-documents" exact>
            {enableOverlay ? (
              <OverlayComp />
            ) : (
              <UploadFormContextProvider>
                <PropertyDetailsForm type="Segregate Documents" />
              </UploadFormContextProvider>
            )}
          </Route>
        )} */}
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/segregate-documents/upload-doc"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? (
                  <OverlayComp />
                ) : (
                  <UploadFormContextProvider>
                    <DragDropFile />
                  </UploadFormContextProvider>
                )}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? (
              <OverlayComp />
            ) : (
              <UploadFormContextProvider>
                <DragDropFile />
              </UploadFormContextProvider>
            )} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/reports/edit/:id"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <EditReports />}
              </Suspense>
            )}
          >
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/drafts/edit/:id"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <EditDraft />}
              </Suspense>
            )}
          >
          </Route>
        )}
      
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/reports/segregator-table/:id"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <ReportEditorTable />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <ReportEditorTable />} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/reports/partition/review/:reportId"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? (
                  <OverlayComp />
                ) : (
                  <LKPartitionReviewSplitPdf />
                )}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <LKPartitionReviewSplitPdf />} */}
          </Route>
        )}

        {authCtx.isLoggedIn() && (
          <Route
            exact
            path="/admin/allowuser/:id/"
            render={() => (
              <Suspense fallback={<LendingSpinner />}>
                {enableOverlay ? <OverlayComp /> : <AllowUser />}
              </Suspense>
            )}
          >
            {/* {enableOverlay ? <OverlayComp /> : <AllowUser />} */}
          </Route>
        )}
        {authCtx.isLoggedIn() && (
          <Route exact path="/coming-soon">
            {enableOverlay ? <OverlayComp /> : <ComingSoon />}
          </Route>
        )}

        {/* 
          @description : Settlin Client Routes
        */} 
        <Route
          exact
          path="/editreports/:reportId/:clientId/:clientSecretKey"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              {enableOverlay ? <OverlayComp /> : <ClientEditReport />}
            </Suspense>
          )}
        >
        </Route>

        <Route
          exact
          path="/partitionreview/:reportId/:clientId/:clientSecretKey"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              {enableOverlay ? <OverlayComp /> : <PartitionReviewSplitPdf />}
            </Suspense>
          )}
        >
        </Route>
        {/* 
          @description : Settlin Client Routes End
        */} 
        {/* ---------------------------------------------------------------------------- */}
        {/* 
        @description :{{URL}}?token=token;
        Routes For Vakil Serach Start
        */}
        
        <Route
          exact
          path="/vakil/dashboard"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? <OverlayComp /> : <VakilSearchDashboard />}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/forms"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? (
                <OverlayComp />
              ) : (
                <UploadFormContextProvider>
                  <VakilSearchPropertyDetailsForms />
                </UploadFormContextProvider>
              )}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/segregate-documents/upload-doc"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? (
                <OverlayComp />
              ) : (
                <UploadFormContextProvider>
                  <SegregateUploadPage />
                </UploadFormContextProvider>
              )}
            </Suspense>
          )}
        ></Route>

        <Route
          exact
          path="/vakil/reports"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? <OverlayComp /> : <VakilReports />}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/reports/edit/:id"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? <OverlayComp /> : <VakilEditReports />}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/reports/segregator-table/:id"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? <OverlayComp /> : <VakilReportEditorTable />}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/partitionreview/:reportId"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? (
                <OverlayComp />
              ) : (
                <VakilPartitionReviewSplitPdf />
              )}
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path="/vakil/report-status"
          render={() => (
            <Suspense
              fallback={<LendingSpinner id={"lending_vakil_spinner"} />}
            >
              {enableOverlay ? <OverlayComp /> : <VakilStatusPage />}
            </Suspense>
          )}
        ></Route>
        {/* Routes For Vakil Serach Ends */}

        <Route path="/report-status">
          <ReportStatusPage />
        </Route>

        <Route
          path="/demo"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <DemoPage />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/faqs"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <FAQs />
            </Suspense>
          )}
        >
          {/* <FAQs /> */}
        </Route>
        <Route
          path="/aboutus"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <Aboutus />
            </Suspense>
          )}
        ></Route>

        <Route
          path="/pricing"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <Pricing />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/careers"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <Careers />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/privacy-policy"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <PrivacyPolicys />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/terms"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <TermsOfUse />
            </Suspense>
          )}
        ></Route>
        <Route
          path="/contactus"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <ContactUsPage />
            </Suspense>
          )}
        >
          {/* <ContactUsPage /> */}
        </Route>
        <Route
          exact
          path="/forgot-password"
          render={() => (
            <Suspense fallback={<LendingSpinner />}>
              <ForgotPassword />
            </Suspense>
          )}
        >
          {/* <ForgotPassword /> */}
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
        {/* </Suspense> */}
        
      </Switch>
    </Layout>
  );
}

export default App;
