import React, { useState } from "react";
import { GiSandsOfTime } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import moment from "moment";

import "./processreportpage.css";

const ReportStatusPage = () => {
  const location = useLocation();
  const [message, setMessage] = useState({
    "upload-processing": "You will be notified once the partition review is ready",
    "upload-failed": "Documents could not be uploaded, please contact us",
    "partition-processing": "You will be notified once the partition review is ready",
    "segregator-processing": "You will be notified once the segregator report is ready",
    "report-processing": "You cannot access this page, please contact us",
    "failed": "Please try again",
    "done": "You cannot access this page, please contact us",
    "partition-review": "Partition review is yet to be completed"
  });

  const [messageWithETA, setMessageWithETA] = useState({
    "upload-processing": "You will be notified once the partition review is ready",
    "upload-failed": "Documents could not be uploaded, please contact us",
    "partition-processing": "Your partition review will be ready in",
    "segregator-processing": "Your segregator report will be ready in",
    "report-processing": "You cannot access this page, please contact us",
    "failed": "Please try again",
    "done": "You cannot access this page, please contact us",
    "partition-review": "Partition review is yet to be completed"
  });

  const getETA = (date) => {
    const end = moment(date);
    const start = moment(new Date());
    const duration = moment.duration(end.diff(start));
    return Math.max(Math.round(duration.asMinutes()), 1);
  }

  return (
    <>
      <div className="process_report_page_full_height">
        <div className="process_report_page_full_height_message_container">
          <div className="message_section">
            <div className="check-fill-icon" style={{ marginBottom: '10px' }}>
              <GiSandsOfTime />
            </div>
            <div className="lk_split_congrats-message text-center">
              {location?.data.status != "partition-review" ?
                <p className="process_report_total_files_count ">
                  {location && location?.data.status == "Please try again" || location?.data.status.includes("fail")
                    ? "We could not process your request. "
                    : "We are processing your request."}
                </p> : <></>}
              <br />
              <p className="process_report_total_files_count">
                {" "}
                {location && location?.data && location.data.status && location.data.eta && location.data.eta.etTimestamp ?
                  `${messageWithETA[location?.data?.status]} ${getETA(location.data.eta.etTimestamp)} mins.`
                  : location.data?.status ? message[location?.data?.status] : " "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportStatusPage;
