import React, { Fragment } from "react";
import ValikSearchMainNavigation from "../../vakilsearch/components/Layout/ValikSearchMainNavigation";

import MainNavigation from "./MainNavigation";
import LibraFooter from "../../vakilsearch/components/Footer/LibraFooter";

const Layout = (props) => {
  // console.log("props,", props);

  const components = {
    mainWebsiteHeader: <MainNavigation />,
    vakilSearchHeader: <ValikSearchMainNavigation />,
  };
  return (
    <Fragment>
      {props.data["route"].includes("vakil")
        ? components["vakilSearchHeader"]
        : components["mainWebsiteHeader"]}
      {/* {components['vakilSearchHeader']} */}
      {/* <MainNavigation /> */}
      <main>{props.children}</main>
     {props.data["route"].includes("vakil") && <LibraFooter/>}
    </Fragment>
  );
};

export default Layout;
